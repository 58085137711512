"use strict";

angular.module("informaApp")
    .service("DrugService", ['BaseApiService', "ConstantsSvc", function (BaseApiService, ConstantsSvc) {
        return {
            getDrugs: function(filter){
                return BaseApiService.post('drugs', filter)
                .then(function (res) {
                    return res.data.data;
                });
                /*
                return $http.post(ConstantsSvc.API.URL + "drugs", filter)
                    .then(function (res) {
                        return res.data.data;
                    });
                */
            }
        };
    }]);